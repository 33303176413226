/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */

import config from '../../package.json';

import {
  GROP_SETUP,
  GROP_QUIT,
  GROP_CONTINUE_LATER,
  GROP_SET_SECTION,
  GROP_SET_ANSWER,
  GROP_SET_LOTID,
  GROP_CREATE,
  GROP_PREVIOUS_LOAD,
  GROP_PREVIOUS_RESET,
  GROP_SET_NEXT,
  GROP_SET_PREVIOUS,
  GROP_SET_PAGE_NEXT,
  GROP_SET_PAGE_PREVIOUS,
  GROP_SET_NUMITEMS,
  GROP_SAVE,
  GROP_CONNECT,
  GROP_REFRESH,
  START,
  FAIL,
  SUCCESS,
} from '../actions/grop';

const { version } = config;

function updateCompletionRate(newState) {
  let numanswers = 0;
  let numquestions = 0;
  let sectionNumanswers = 0;
  let sectionNumquestions = 0;

  newState.sections.map((section, sectionId) => {
    let completed = true;
    section.questions.map(question => {
      const answer = section.answers[question.id];

      if (answer) {
        numanswers += 1;
      } else {
        completed = false;
      }

      numquestions += 1;

      if (sectionId === newState.section) {
        if (answer) {
          sectionNumanswers += 1;
        }
        sectionNumquestions += 1;
      }

      return true;
    });
    newState.sections[sectionId].completed = completed;
    newState.sections[sectionId].completionRate =
      Math.round((sectionNumanswers / sectionNumquestions) * 1000) / 10;
    return true;
  });

  newState.fullyCompleted =
    newState.sections[0].completed &&
    newState.sections[1].completed &&
    newState.sections[2].completed &&
    newState.sections[3].completed;

  newState.sections.completionRate =
    Math.round((numanswers / numquestions) * 1000) / 10;

  // let's check if page is full!
  const {
    sections,
    section,
    page: { current, numitems },
  } = newState;

  const pointer = current * numitems;

  let i = 0;

  let full = true;
  for (i = pointer; i < pointer + numitems; i += 1) {
    const question = sections[section].questions[i];
    if (question) {
      const answer = sections[section].answers[question.id] || null;
      if (!answer) {
        full = false;
        break;
      }
    } else {
      full = false;
    }
  }
  newState.page.full = full;
}


export const initialState = {
  version,
  api: process.env.PSYMETRIK_BACKEND_URL,
  fullyCompleted: false,
  sections: null,
  section: 0,
  current: -1,
  pointer: 0,
  lotId: null,
  resultId: null,
  previous_results: null,
  dev: true,
  nosave: false,
  prepopulate: null,
  user: {
    firstname: null,
    lastname: null,
    birthday: null,
    gender: null,
    gender_chosen: null,
    gender_group: null,
    email: null,
    residence: null,
  },
  page: {
    current: 0,
    numitems: 5,
    full: false,
  },
  session: {
    dev: null,
    end: null,
    duree: 0,
    expiration: null,
    connected: false,
    connecting: false,
    refreshing: false,
  },
  load: {
    inprogress: false,
    error: null,
    status: null,
  },
  create: {
    inprogress: false,
    error: null,
    status: null,
  },
  save: {
    inprogress: false,
    error: null,
    status: null,
    complete: null,
  },
  quitted: false,
};

export default function grop(state = initialState, { type, payload, status }) {
  const newState = { ...state };
  let current = null;

  switch (type) {
    case GROP_CONTINUE_LATER:
      return state;
    case GROP_QUIT:
      return { ...initialState, quitted: true };
    case GROP_CONNECT:
    case GROP_REFRESH:
      switch (status) {
        case SUCCESS:
          newState.session = {
            ...newState.session,
            ...payload,
          };
          newState.session.connected = true;
          newState.session[
            type === GROP_REFRESH ? 'refreshing' : 'connecting'
          ] = false;
          break;
        case START:
          newState.session[
            type === GROP_REFRESH ? 'refreshing' : 'connecting'
          ] = true;
          break;
        default:
        case FAIL:
          newState.session[
            type === GROP_REFRESH ? 'refreshing' : 'connecting'
          ] = false;
          newState.session.connected = false;
      }
      return newState;

    case GROP_SETUP:
      // Ugly: expecting sections and page.numitems in payload
      switch (status) {
        case SUCCESS:
          newState.sections = payload.sections;
          newState.page.numitems = payload.numitems;
          // this should be overriden by GROP_CREATE and GROP_PREVIOUS_LOAD
          newState.session.startTime = new Date();
          newState.dev = payload.dev;
          newState.nosave = payload.nosave;
          newState.prepopulate = payload.prepopulate;
          break;
        case START:
          break;
        case FAIL:
          newState.connected = false;
          break;
        default:
        //
      }
      return newState;

    case GROP_SET_SECTION:
      newState.section = payload;
      newState.current = -1;
      newState.page.current = 0;
      updateCompletionRate(newState);
      return newState;

    case GROP_SET_PREVIOUS: {
      current = state.current - 1;
      let { section } = state;

      if (current === -1) {
        if (section > 0) {
          section = state.section - 1;
          current = state.sections[state.section].questions.length - 1;
        } else {
          // you're at the beginning, revert to prior state
          current = 0;
          section = 0;
        }
      }

      newState.current = current;
      newState.section = section;

      // updateCompletionRate(newState);
      return newState;
    }

    case GROP_SET_NEXT: {
      current = state.current + 1;
      let { section } = state;
      if (state.sections[state.section].questions.length === current) {
        if (state.section < 3) {
          current = 0;
          section += 1;
        } else {
          // you're at the end, revert to prior state
          current = state.current;
        }
      }

      newState.current = current;
      newState.section = section;

      updateCompletionRate(newState);
      return newState;
    }

    case GROP_SET_LOTID:
      newState.lotId = parseInt(payload, 10);
      return newState;

    case GROP_SET_NUMITEMS:
      newState.page.current = 0;
      newState.page.numitems = parseInt(payload, 10);
      return newState;

    case GROP_SET_PAGE_PREVIOUS:
      if (newState.page.current > 0) newState.page.current -= 1;
      updateCompletionRate(newState);
      return newState;

    case GROP_SET_PAGE_NEXT: {
      const numQuestions = newState.sections[newState.section].questions.length;
      const numPages = Math.floor(numQuestions / newState.page.numitems);
      if (newState.page.current < numPages) newState.page.current += 1;
      updateCompletionRate(newState);
      return newState;
    }

    case GROP_CREATE:
      switch (status) {
        case START:
          newState.create.inprogress = true;
          newState.create.status = 0;
          break;
        case FAIL:
          newState.create.inprogress = false;
          newState.create.status = -1;
          break;
        case SUCCESS:
          newState.create.inprogress = false;
          newState.create.status = 1;
          newState.resultId = parseInt(payload.id, 10);
          newState.user = payload.user; // firstname, lastname, gender, birthday, residence, email
          newState.session.startTime = new Date();
          break;
        default:
      }
      return newState;

    case GROP_PREVIOUS_LOAD:
      switch (status) {
        case START:
          newState.load.inprogress = true;
          newState.load.status = 0;
          break;
        case FAIL:
          newState.load.inprogress = false;
          newState.load.status = -1;
          break;
        /* WTF? Should there have been a case NO_PREVIOUS?
          newState.load.status = 1;
          newState.load.inprogress = false;
          newState.resultId = null;
          newState.user = initialState.user;
          break; */
        case SUCCESS:
          newState.load.status = 1;
          newState.load.inprogress = false;
          newState.resultId = parseInt(payload.id, 10);
          newState.previous_results = payload.results;
          newState.user = payload.user;
          newState.session.duree = payload.duree;
          newState.session.startTime = new Date();
          //* ********** FILL PREVIOUS RESULTS
          if(newState.previous_results) {
            newState.section = 0;
            let isSet = false;

            newState.sections.forEach((section, sectionIndex) => {
              newState.current = 0;
              let complete = true;
              section.questions.forEach((question, questionIndex) => {
                if (newState.previous_results[question.id]) {
                  section.answers[question.id] =
                  newState.previous_results[question.id];

                  newState.current = questionIndex;
                } else {
                  complete = false;
                }
              });

              if (!complete && !isSet) {
                newState.page.current = Math.floor(newState.current / newState.page.numitems);

                newState.section = sectionIndex;
                newState.current += 1;

                isSet = true;
              }
            });
          }
          updateCompletionRate(newState);
          break;
        default:
      }
      return newState;

    case GROP_PREVIOUS_RESET:
      newState.previous_results = null;
      return newState;

    case GROP_SAVE:
      switch (status) {
        case START:
          newState.save.inprogress = true;
          newState.save.complete = false;
          newState.save.silent = payload;
          return newState;

        case FAIL:
          newState.save.inprogress = false;
          newState.save.complete = false;
          newState.save.error = payload;
          return newState;

        case SUCCESS:
          newState.save.inprogress = false;
          newState.save.error = null;
          newState.save.complete = true;
          return newState;
        default:
      }
      return newState;

    case GROP_SET_ANSWER: {
      const currentSection = newState.section;

      newState.sections[currentSection].questions.map(
        (question, questionIndex) => {
          if (question.id === payload.id) {
            newState.sections[currentSection].answers[question.id] =
              payload.val;
            newState.current = questionIndex;
          }
          return true;
        },
      );

      updateCompletionRate(newState);
      return newState;
    }

    default:
      return state;
  }
}
