import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import { useGlobals } from '../../lib/global-content';
import Access from '../../components/Access';

import {
  redirect,
  UI_VIEW_TEST,
} from '../../actions/ui';

const useStyles = makeStyles(theme => ({
  wrap: {
    paddingRight: 30,
    paddingLeft: 30,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}))

const Home = props => {
  const classes = useStyles();
  const { data } = useGlobals();

  return (
    <div className={classes.wrap}>
      <div
        style={{ padding: '0 20px 0 0', flex: '1 50%' }}
        className={classes.introText}
        dangerouslySetInnerHTML={{ __html: data && data.home.body.value }}
      />
      <div style={{ flex: '1 50%' }}>
        <Paper style={{ padding: '20px 30px' }}>
          <Access onSuccess={() => props.redirect(UI_VIEW_TEST)} />
        </Paper>
      </div>
    </div>
  );
}

const mapState = state => ({
  view: state.ui.view,
  content: state.ui.content.home,
  showingHelp: state.ui.help,
});

export default connect(mapState, { redirect })(Home);
