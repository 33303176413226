import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getParameterByName } from '../tools/helpers';
import Loading from '../components/Loading';
import Home from './views/home';
import Test from './views/test';
import Complete from './views/complete';

import {
  redirect,
  setDialog,
  UI_VIEW_HOME,
  UI_VIEW_ACCESS,
  UI_VIEW_TEST,
  UI_VIEW_CODE,
  UI_VIEW_COMPLETE,
  UI_VIEW_QUIT,
} from '../actions/ui';

import { load } from '../actions/grop';

const checkContinue = async ({ setLoaded, ...props }) => {
  const continueToken = getParameterByName('c');

  if (continueToken) {
    const valid = await props.load(continueToken);
    if (valid === 'closed') {
      props.setDialog({
        title: 'Erreur de chargement',
        message:
          "Le code qui vous a été fourni n'est plus actif. <br />Veuillez contacter votre professionnel",
        noclose: true,
      });
      setLoaded(true);
      return;
    }

    if (valid) {
      props.redirect(UI_VIEW_TEST);
    } else {
      props.setDialog({
        title: 'Erreur de chargement',
        message:
          'Le lien de poursuite du test semble erroné.<br />Veuillez contacter votre professionnel',
        noclose: true,
      });
    }
  }
  setLoaded(true);
}

const Index = props => {
  const [Page, setPage] = useState(<Loading message="Validation" centered />);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    checkContinue({ ...props, setLoaded});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loaded) {
      return;
    }
    let page;
    switch (props.view) {
      case UI_VIEW_QUIT:
      case UI_VIEW_COMPLETE:
        page = <Complete />;
        break;
      case UI_VIEW_TEST:
        page = <Test />;
        break;
      default:
      case UI_VIEW_ACCESS:
      case UI_VIEW_CODE:
      case UI_VIEW_HOME:
      case 'home':
        page = <Home />;
        break;
    }
    setPage(page);
  }, [loaded, props.view]);

  return <><div data-cy="view" style={{ display: 'none' }}>{props.view || 'home'}</div>{Page}</>;
}

const mapState = state => ({
  view: state.ui.view,
});

export default connect(mapState, { redirect, setDialog, load })(Index);
