
const pubkey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApDEkrhvJxx+lWtmTMnKY
RPwC9evZCufg1P7zSpUY7f57e3J3tnlFnc2PHkJDgzt6Lw7BFCCqJHKJOny8DLoa
7RTOj/BYhBIoURL/xA8z/9SMNJPZ7X57El87+Pb5c2vz/eCU8hu4v/ExIuERtl9t
9IBte6nOgoyr3aHeBbME5w/mmes5UIJayEnpn2StbTRvVdG7RYOHZDYhZmRLhat3
o4YA+uT0e/w+Do8NobNpaMhQZ/IsXV441dpM6ZMRmjjaEas0/Z6a432FcwNnj77u
F3fUxA6i3/EQ09Q0hKxDtZ7IYWfg8AF5cyt6bbs4uvqdlAs3waJTTqBpPN5LfTb5
qQIDAQAB
-----END PUBLIC KEY-----`

module.exports = pubkey;