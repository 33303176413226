import { useState, useEffect } from 'react';

const palettes = [
  {
    "main": [
      "#447388",
      "#cc5544",
      "#80a6af"
    ],
    "dark": [
      "#336277",
      "#bb4433",
      "#70959f"
    ]
    },{
      "main": [
        "#889a9f",
        "#f76019",
        "#889a9f"
      ],
      "dark": [
        "#77898f",
        "#f05011",
        "#77898f"
      ]
    },{
      "main": [
        "#447388",
        "#ee6655",
        "#98aaaf"
      ],
      "dark": [
        "#336277",
        "#dd5544",
        "#87999f"
      ]
  },{
    "main": [
      "#447788",
      "#cc6655",
      "#ff9955"
    ],
    "dark": [
      "#336677",
      "#bb5544",
      "#ee8844"
    ]
  },{
    "main": [
      "#3399aa",
      "#5f5588",
      "#586a65"
    ],
    "dark": [
      "#228899",
      "#4f4477",
      "#475954"
    ]
  },{
    "main": [
      "#b0b7bf",
      "#cccccc",
      "#a0afa9"
    ],
    "dark": [
      "#a0a7af",
      "#bbbbbb",
      "#909f99"
    ]
  },{
    "main": [
      "#b0bbbf",
      "#ff9911",
      "#b0b7bf"
    ],
    "dark": [
      "#a0abaf",
      "#ff8800",
      "#a0a7af"
    ]
  }
];

const useTheme = () => {

  const [current, setCurrent] = useState(0);
  const [fullHeader, setFullHeader] = useState(false);
  const [state, setState] = useState({
    current,
    palettes,
    palette: palettes[current],
    change: current => setCurrent(current),
    fullHeader,
    setFullHeader
  });

  useEffect(() => {
    setState({
      current,
      palettes,
      palette: palettes[current],
      change: c => setCurrent(c),
      fullHeader,
      setFullHeader
    });
  }, [current, fullHeader])

  return state;
}

export default useTheme;
