import React from 'react';
import { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import useContent from '../hooks/useContent';
import Error from '../pages/_error';
import useTheme from '../hooks/useTheme';
import { setup } from '../actions/grop';
import { redirect, setDialog } from '../actions/ui';
import Loading from '../components/Loading'

export const GlobalContext = React.createContext({
  content: null,
  menu: {},
  error: null,
  theme: {}
})

export const useGlobals = () => useContext(GlobalContext);

const GlobalContainer = ({ router, children, ...props }) => {
  const {
    error: contentError,
    loading: contentLoading,
    data,
  }  = useContent();

  const theme = useTheme();

  useStyles();

  useEffect(() => {
    if (data) {
      props.setup(data);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  
  if(contentError) return <Error {...contentError} />;
  if (!data) return <Loading message="Chargement du contenu" centered />;

  return <GlobalContext.Provider value={{
    theme,
    data,
    route: {
      url: router && router.asPath,
    },
    error: contentError,
    loading: contentLoading
  }}>
    {children}
  </GlobalContext.Provider>;
}

const useStyles = makeStyles(theme => ({
  '@global':  {
    body: {
      padding: '15px 0',
      [theme.breakpoints.up('sm')]: {
        padding: '15px 5vw'
      },
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: 16,
    },
    input: {
      fontWeight: 300,
    },
    label: {
      fontWeight: 300,
    },
    a: {
      textDecoration: 'none',
      color: '#911',
      '&:hover':{
        textDecoration: 'underline'
      }
    },
    ul: {
      paddingLeft: 16
    },
    li: {
      marginBottom: 15,
    },
    blockquote: {
      margin: 0,
      padding: '.5em 2em',
      borderLeft: '4px solid #ddd'
    }
  }
}))

export default connect(null, { setup, setDialog, redirect })(GlobalContainer);
