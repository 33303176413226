import React from 'react';
import { connect } from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

import { save, refresh } from '../../actions/grop';
import { setDialog } from '../../actions/ui';

class TimeKeeper extends React.Component {
  state = {
    timeleft: null,
    warn: 5 * 60, // seconds
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { connected } = prevProps;
    if (!connected && this.props.connected) {
      this.initialize();
    }
  }

  componentWillUnmount() {
    this.cleanup();
  }

  intervalId = null;

  cleanup = () => {
    clearInterval(this.intervalId);
  }

  calculateTimeLeft = () => {
    const now = new Date();
    const timeleft = Math.round((this.props.sessionExpiration - now) / 1000); // to seconds
    return timeleft;
  }

  checkStatus = () => {

    if (this.props.quitted) {
      this.cleanup();
      return;
    }

    const timeleft = this.calculateTimeLeft();

    if (timeleft <= 0) {
      this.timedOut();
    }

    this.setState({ timeleft });
  };

  timedOut = () => {
    this.props.save(true);
    this.props.setDialog({
      title: 'Temps écoulé',
      message: 'Vous avez malheureusement dépassé le temps alloué à la passation du test.',
      noclose: true,
      actions: [
        <Button
          onClick={() => window.location.reload()}
          key="reload"
        >
          Quitter
        </Button>,
        <Button
          onClick={this.refresh}
          variant="contained"
          key="refresh"
          color="primary"
        >
          Poursuivre la session
        </Button>,
      ],
    });
    this.cleanup();
  };

  initialize = () => {
    if (!this.intervalId) {
      this.intervalId = setInterval(this.checkStatus, 1000);
    }
  };

  refresh = async () => {
    const result = await this.props.refresh();
    if (result) {
      this.calculateTimeLeft();
      this.initialize();
      this.props.setDialog(false);
    } else {
      this.props.setDialog({
        title: 'Erreur de rafraichissement',
        message: 'Il est malheureusement impossible de poursuivre la session.',
        noclose: true,
        actions: [
          <Button
            onClick={() => window.location.reload()}
            variant="contained"
            key="reload"
          >
            Redémarrer
          </Button>,
        ],
      });
    }
  };

  render() {
    const { timeleft, warn } = this.state;

    if (timeleft <= 0) return null;

    const mins = Math.floor(timeleft / 60);
    const showWarning = timeleft < warn && timeleft > 0;

    return (
      <React.Fragment>
        <Snackbar
          message={`Votre session se termine dans ${
            mins <= 1 ?
              'moins d\'une minute' : `${mins} minutes`}.`}
          open={showWarning}
          action={
            <Button
              color="primary"
              size="small"
              onClick={this.refresh}
              disabled={this.props.refreshing}
            >
              {"J'ai besoin de plus de temps"}
            </Button>
          }
        />

        {/* !showWarning && (
          <div
            style={{
              position: 'absolute',
              left: 10,
              bottom: 10,
              color: '#999',
              fontSize: 12,
            }}
          >
            {mins} mins
          </div>
        ) */}
      </React.Fragment>
    );
  }
}

const mapState = state => ({
  saving: state.grop.save.inprogress,
  saved: state.grop.save.complete,
  silent: state.grop.save.silent,
  fullyCompleted: state.grop.fullyCompleted,
  sections: state.grop.sections,
  isDev: state.grop.session.dev,
  sessionExpiration: state.grop.session.expiration,
  connected: state.grop.session.connected,
  refreshing: state.grop.session.refreshing,
  quitted: state.grop.quitted,
});

export default connect(mapState, { save, refresh, setDialog })(TimeKeeper);
