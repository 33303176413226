import React from 'react';

import { connect } from 'react-redux';
import { redirect } from '../../actions/ui';

class Header extends React.Component {
  logout = async () => {
    await this.props.logout();
  };

  render() {
    let { firstname, lastname } = this.props;

    return (
      <div
        style={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 15,
          padding: '0 30px',
        }}
      >
        <div
          key="psymetrik"
          // onClick={() => history.push('')}
          style={{ marginTop: 6 }}
        >
          <img src='/static/images/logo.png' alt="Psymetrik" />
          <img src='/static/images/signature.png' alt="Psymetrik" />
        </div>

        {firstname && <div data-cy="bonjour">Bonjour <span style={{
          fontWeight: 500,
        }}>{firstname} {lastname}</span></div>}
      </div>
    );
  }
}

const mapState = state => ({
  firstname: state.grop.user.firstname,
  lastname: state.grop.user.lastname,
});

export default connect(mapState, { redirect })(Header);
