/**
 * Helper Functions
 */
import JWTWebToken from 'jsonwebtoken';
import pubkey from './key';

const REFRESH = 'Rbwe7AMl31s';
const ACCESS = 'A26raiM0cDy';
/*
function setCookie(key, value, exp) {
  let expires = '';

  const date = new Date();
  date.setTime(date.getTime() + exp * 1000);
  expires = "; expires=" + date.toUTCString();

  document.cookie = key + "=" + (value || "")  + expires + "; path=/";
}

function getCookie(key) {
  const nameEQ = key + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i + 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(key) {
  document.cookie = `${key}=; Max-Age=-99999999;`;
}
*/
export default class JWT {
  static instance = null;

  static getInstance = () => {
    /*
    NOTE: i'm being a little lazy: disabling the instantiation and retrieving
    the token from local storage -- hem cookie, dixit https://dev.to/rdegges/please-stop-using-local-storage-1i04 --  on all calls
    */
    if (JWT.instance === null) {
      // const token = JSON.parse(sessionStorage.getItem(TOKEN_ID));
      // const token = JSON.parse(getCookie());
      // const token = getCookie(ACCESS);
      const token = sessionStorage.getItem(ACCESS);
      // console.trace();

      if (token !== null) {
        // const { access_token, expires_in, refresh_token } = token;
        try {
          const now = new Date();
          JWTWebToken.verify(
            token,
            pubkey,
            {
              ignoreNotBefore: true,
              clockTimestamp: now.getSeconds(),
            },
            (err, decoded) => {
              // decoded: aud (audience), exp (expiration), iat (issued at), jti, scopes (roles), sub (uid)

              JWT.instance = {
                ...decoded,
              };

              if (err) throw err;
            },
          );
        } catch (err) {
          if (err.name === 'TokenExpiredError') {
            console.error('token.expired', JWT.instance);
          } else if (err.name === 'NotBeforeError') {
            // these should be ignored
            console.error('token.nbf', JWT.instance);
          } else {
            console.error(err);
          }
        }
      }
    }

    return JWT.instance;
  };

  static save = async token => {
    // await sessionStorage.setItem(TOKEN_ID, JSON.stringify(token));
    // setCookie(JSON.stringify(token), token.exp);
    await sessionStorage.setItem(ACCESS, token.access_token);
    await sessionStorage.setItem(REFRESH, token.refresh_token);
    // setCookie(ACCESS, token.access_token);
    // setCookie(REFRESH, token.refresh_token);
    JWT.instance = null; // @FIXME: lousy way to reset instance
  };

  static destroy = () => {
    // sessionStorage.removeItem(TOKEN_ID);
    sessionStorage.removeItem(ACCESS);
    sessionStorage.removeItem(REFRESH);
    // eraseCookie(ACCESS);
    // eraseCookie(REFRESH);
  };

  static isValid = () => JWT.getInstance() !== null;
  // static isExpired = () => JWT.getInstance().expired;
  static getExpiration = () => {
    if (JWT.isValid()) {
      const { exp } = JWT.getInstance();
      return new Date(exp * 1000);
    }

    return null;
  };

  static getIssuedAt = () => {
    if (JWT.isValid()) {
      const { iat } = JWT.getInstance();
      return new Date(iat * 1000);
    }

    return null;
  };

  // static getAccessToken = () => getCookie(ACCESS);
  // static getRefreshToken = () => getCookie(REFRESH);
  static getAccessToken = () => sessionStorage.getItem(ACCESS);
  static getRefreshToken = () => sessionStorage.getItem(REFRESH);
}
