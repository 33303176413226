
const TestTheme = theme => ({
 root: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },

  container: {
    margin: '0 auto',
    padding: '0 0 40px',
    maxWidth: '1024px',
  },

  wrapper: {
    width: '100%',
    minHeight: '70vh',
    textAlign: 'center',
  },

  completion: {
    position: 'fixed',
    bottom: '22px',
    right: '10px',
    width: '50px',
    textAlign: 'center',
    fontSize: '.8em',
    fontWeight: '500',
    color: '#aaa',
  },

  small: {
    fontSize: '.8em',
    color: '#666',
  },

  row: {
    position: 'relative',
    minHeight: '48px',
    padding: '10px 5vw',
    borderBottom: '1px solid #ccc',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'stretch',
      minHeight: '48px',
      padding: '0 20px',
    },
  },

  entete: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 20px',
  },
  enteteLeft: {
    fontWeight: 700,
  },
  enteteRight: {
    fontWeight: 300,
    textAlign: 'right',
  },

  lexiqueSectionD: {
    display: 'block',
    fontSize: '.8em',
    color: '#888',
  },

  question: {
    display: 'flex',
    /* alignItems: 'center', */
    justifyContent: 'center',
    padding: '14px 0',
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'left',
      // flexBasis: '100%',
      flexRow: '5',
      flexBasis: '50%',
    },
  },

  headLegend: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 0px',
    [theme.breakpoints.up('md')]: {
      padding: '0',
      flexBasis: '50%',
    },
  },

  headLegendPinned: {
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 4,
      background: '#fa0',
      padding: '2px 25px',
    },
  },

  headlabel: {
    fontSize: '.8em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1em',
    },
  },

  num: {
    marginRight: '10px',
    fontSize: '.85em',
    color: '#888',
    minWidth: '15px',

    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    background: '#ddd',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,

    [theme.breakpoints.up('md')]: {
      width: 36
    },
  },

  labelShort: {
    fontWeight: '300',
    fontSize: '.7em',
    marginBottom: '1em',
  },

  label: {
    textAlign: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    color: 'rgba(0, 0, 0, 0.87)',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      paddingLeft: 30,
      paddingRight: 0,
    }
  },

  footerWrapper: {
    padding: '10px 20px',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },

  footerPageNum: {
    fontWeight: 400,
    color: '#333',
    marginRight: 10
  },

  footerPage: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'inherit',
      flexBasis: '40%',
      textAlign: 'left',
      alignItems: 'center',
    },
  },

  footerNav: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
      flexBasis: '60%',
    },
  },

  footerNumItems: {
    fontWeight: '300',
    paddingLeft: '10px',
  },

  footerButton: {},

  radios: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px 10px',
    [theme.breakpoints.up('md')]: {
      padding: '0',
      flexBasis: '50%',
    },
  },

  radio: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '8px 0',
    /* padding: '10px', */
    flexBasis: '20%',
    flexRow: 1,
    margin: '0 8px',
    cursor: 'pointer',
  },
  /*
  table{
    background: '#fff',
  },
  */
  done: {
    /* borderBottom-color: '#d6d6d6', */
    background: '#f8f8f8 !important',
    color: '#888',
    transition: 'all 0.9s',
  },

  head: {
    fontWeight: '500',
    textAlign: 'left',
    fontSize: '.8em',
    background: '#fa0',
    color: '#fff',
  },

  headQuestion: {
    fontSize: '1.3em',
  },

  consignesQuestion: {
    fontWeight: '500',
    fontSize: '1.4em',
  },

  consignes: {
    ol: {
      paddingLeft: '16px',
    },

    li: {
      marginBottom: '10px',
    },
  },

  expliq: {
    display: 'inline-block',
    marginLeft: '5px',
    fontSize: '0.8em',
    lineHeight: '09em',
    fontWeight: 'bold',
    textAlign: 'center',
    borderRadius: '16px',
    background: '#999',
    padding: '3px 5px',
    color: '#fff',
    cursor: 'pointer',
    transition: 'all 0.2s',
  },

  /* boutons ui_layout_table */
  bouton: {
    width: '20px',
    height: '20px',
    border: '2px solid #aaa',
    borderRadius: '20px',
    background: '#eee',
    transition: 'all 0.2s',
    '&:hover': {
      // add :focus???
      background: '#ddd',
      borderColor: '#aaa',
      transition: 'all 0.2s',
      outline: 'none',
    },
  },

  boutonSelected: {
    background: '#f90 !important',
    borderColor: '#f90 !important',
  },

  odd: {
    /* background: '#f4f4f4', */
  },

  even: {
    /* background: '#f8f8f8', */
  },
});

export default TestTheme;
