import { useQuery } from 'graphql-hooks';
/**
 * NOTE: This fires waaaay to often. Granted, caching may prevent wreaking havoc
 * but this still doesn't feel right -- need a way to use an
 */
const useContent = () => {
  const { loading, error, data  } = useQuery(INITIAL_QUERY);
  return { loading, error: error && { statusCode: 503, message: 'L\'application n\'est pas disponible' }, data };
}

const INITIAL_QUERY = `query gropFull {
  labels: Labels
  message: SystemMessage

  section0: GropTest(section: "a") {
    id
    label
    lexique
  }

  section1: GropTest(section: "b") {
    id
    label
    lexique
  }

  section2: GropTest(section: "c") {
    id
    label
    lexique
  }

  section3: GropTest(section: "d") {
    id
    label
    lexique
  }

  consignes0: nodeById(id: "946", language: FR) {
    ...Consigne
  }
  consignes1: nodeById(id: "947", language: FR) {
    ...Consigne
  }
  consignes2: nodeById(id: "948", language: FR) {
    ...Consigne
  }
  consignes3: nodeById(id: "949", language: FR) {
    ...Consigne
  }

  home: nodeById(id: "950", language: FR) {
    ... on NodeGropContent {
      body {
        value
      }
    }
  }

  dialogcompleted: nodeById(id: "951", language: FR) {
    ... on NodeGropContent {
      body {
        value
      }
    }
  }

  dialogquit: nodeById(id: "952", language: FR) {
    ... on NodeGropContent {
      body {
        value
      }
    }
  }

  postcomplete: nodeById(id: "953", language: FR) {
    ... on NodeGropContent {
      body {
        value
      }
    }
  }

  postquit: nodeById(id: "954", language: FR) {
    ... on NodeGropContent {
      body {
        value
      }
    }
  }
}

fragment Consigne on NodeGropContent {
  body {
    value
  }
}
`;


export default useContent;
