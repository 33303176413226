import React from 'react';

import { connect } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconOK from '@material-ui/icons/CheckCircle';
import IconLoading from '@material-ui/icons/Refresh';

import Slide from '@material-ui/core/Slide';

import {
  debug as getOutput,
  refresh,
  connect as APIConnect,
  save,
  setAnswer,
  setSection,
  helloWorld,
  fillCurrentSection,
  fillEntireTest,
} from '../../actions/grop';

import { setDialog } from '../../actions/ui';
import { getParameterByName } from '../../tools/helpers';

class Debug extends React.Component {
  state = {
    open: false,
    showDebug: false,
  };

  componentDidMount() {
    if (getParameterByName('dbg')) this.setState({ open: true });
    document.addEventListener('keydown', this.handleKey);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKey);
  }

  handleKey = event => {
    // 115 == F4
    if (event.shiftKey && event.ctrlKey && event.keyCode === 113) {
      this.setState({ open: !this.state.open });
    }
  };

  tester = async type => {
    const result = await this.props[type]();
    let title;
    let message;

    switch (type) {
      case 'APIConnect':
        title = 'Nouvelle connexion';
        message = result
          ? 'La connexion a été renouvelée'
          : 'La connexion semble impossible';
        break;
      case 'refresh':
        title = 'Tentative de reconnexion';
        message = result
          ? 'La connexion semble rafraichit'
          : 'Impossible de rafraichir la connexion';
        break;
      case 'helloWorld':
        title = 'Test de connexion';
        message = result
          ? 'La connexion semble active'
          : 'La connexion ne semble pas valide';
        break;
      default:
    }
    this.props.setDialog({ title, message });
  };

  render() {
    const { saving, saved } = this.props;

    const appStyle = {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      zIndex: 2000,
    };

    const output = this.state.open ? this.props.getOutput() : '';

    return (
      <div style={appStyle}>
        <Slide direction="up" in={this.state.open} mountOnEnter unmountOnExit>
          <div>
            <Slide
              direction="up"
              in={this.state.showDebug}
              mountOnEnter
              unmountOnExit
            >
              <Paper
                style={{
                  padding: '0 20px 12px',
                }}
              >
                <form
                  id="debug-form"
                  action={`${window && window.App && window.App.apiUrl}/debogue`}
                  method="POST"
                  style={{ width: '100%' }}
                >
                  <TextField
                    multiline
                    fullWidth
                    label="Débogage"
                    name="debogue"
                    rows={output.split(/\r\n|\r|\n/).length}
                    margin="normal"
                    inputProps={{
                      style: {
                        fontSize: 11,
                        padding: '0px 15px 10px',
                        background: '#eee',
                      },
                    }}
                    value={output}
                  />
                  <div style={{ textAlign: 'right' }}>
                    <Button
                      key="debug"
                      size="small"
                      onClick={() => this.setState({ showDebug: false })}
                    >
                      Fermer
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      key="send"
                      size="small"
                      onClick={() =>
                        document.getElementById('debug-form').submit()
                      }
                    >
                      Envoyer
                    </Button>
                  </div>
                </form>
              </Paper>
            </Slide>

            <Paper
              style={{
                padding: '8px 20px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              elevation={3}
            >
              <small style={{ marginRight: 10 }}>
                Version {this.props.version} ({process.env.NODE_ENV})
              </small>

              <div>
                <Button
                  key="test"
                  size="small"
                  onClick={() => this.tester('helloWorld')}
                  disabled={false}
                >
                  Tester la connexion
                </Button>

                <Button
                  key="refresh"
                  onClick={() => this.tester('refresh')}
                  disabled={this.props.refreshing}
                  size="small"
                >
                  Réanimer
                </Button>

                <Button
                  key="revive"
                  size="small"
                  onClick={() => this.tester('APIConnect')}
                  disabled={this.props.connecting}
                >
                  Réssusciter
                </Button>

                <Button
                  key="save"
                  size="small"
                  onClick={() => this.props.save()}
                  disabled={saving}
                >
                  Sauvegarder{' '}
                  {saving ? (
                    <IconLoading style={{ color: '#bbb' }} />
                  ) : saved ? (
                    <IconOK style={{ color: '#3c7' }} />
                  ) : (
                    ''
                  )}
                </Button>

                <Button key="fill" size="small" onClick={() => this.props.fillCurrentSection()}>
                  Remplir section
                </Button>

                <Button
                  key="fullfill"
                  size="small"
                  onClick={() => this.props.fillEntireTest()}
                >
                  Remplir test
                </Button>

                <Button
                  key="debug"
                  size="small"
                  onClick={() =>
                    this.setState({ showDebug: !this.state.showDebug })
                  }
                >
                  Débogage
                </Button>
              </div>

              <Button
                size="small"
                onClick={() => this.setState({ open: !this.state.open })}
              >
                Fermer
              </Button>
            </Paper>
          </div>
        </Slide>
      </div>
    );
  }
}

const mapState = state => ({
  refreshing: state.grop.session.refreshing,
  connecting: state.grop.session.connecting,
  connected: state.grop.session.connected,
  saving: state.grop.save.inprogress,
  savingError: state.grop.save.error,
  saved: state.grop.save.complete,
  sections: state.grop.sections,
  section: state.grop.section,
  version: state.grop.version,
});

export default connect(
  mapState,
  {
    getOutput,
    APIConnect,
    refresh,
    save,
    setAnswer,
    helloWorld,
    setDialog,
    setSection,
    fillCurrentSection,
    fillEntireTest,
  },
)(Debug);
