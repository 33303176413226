import React from 'react';
import { connect } from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

class SystemMessage extends React.Component {
  state = {
    open: true,
  };

  handleClose = () => this.setState({ open: false });

  render() {
    const { message } = this.props;
    if (!message) return [];
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={this.state.open}
      >
        <SnackbarContent
          message={message}
          style={{ backgroundColor: '#900', fontWeight: 300 }}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    );
  }
}

const mapState = state => ({
  message: state.ui.system_message,
});

export default connect(mapState)(SystemMessage);
