import { combineReducers } from 'redux';
import grop from './grop';
import ui from './ui';

export default function createRootReducer() {
  return combineReducers({
    grop,
    ui,
  });
}
