import React from 'react';
import Button from '@material-ui/core/Button';
/*
import resultUpdateQuery from './graphql/resultupdate.graphql';
import resultCreateQuery from './graphql/resultcreate.graphql';
import resultLoadQuery from './graphql/resultload.graphql';
import fullQuery from './graphql/full.graphql';
import getLotIDFromNameQuery from './graphql/lotidfromname.graphql';
import helloWorldQuery from './graphql/helloworld.graphql';
*/
import JWT from '../core/jwt';

import { UI_DIALOG, UI_ADD_CONTENT, UI_MESSAGE } from './ui';
import { getParameterByName } from '../tools/helpers';

export const GROP_SETUP = 'GROP_SETUP';
export const GROP_CONNECT = 'GROP_CONNECT';
export const GROP_REFRESH = 'GROP_REFRESH';
export const GROP_QUIT = 'GROP_QUIT';
export const GROP_CONTINUE_LATER = 'GROP_CONTINUE_LATER';
export const GROP_SET_SECTION = 'GROP_SET_SECTION';
export const GROP_SET_ANSWER = 'GROP_SET_ANSWER';
export const GROP_SET_LOTID = 'GROP_SET_LOTID';
export const GROP_SET_NEXT = 'GROP_SET_NEXT';
export const GROP_SET_PREVIOUS = 'GROP_SET_PREVIOUS';
export const GROP_SET_PAGE_NEXT = 'GROP_SET_PAGE_NEXT';
export const GROP_SET_PAGE_PREVIOUS = 'GROP_SET_PAGE_PREVIOUS';
export const GROP_SET_RESULTID = 'GROP_SET_RESULTID';
export const GROP_SET_NUMITEMS = 'GROP_SET_NUMITEMS';

export const GROP_ADMIN = 'GROP_ADMIN';
export const GROP_ADMIN_FILL = 'GROP_ADMIN_FILL';

export const GROP_PREVIOUS_RESET = 'GROP_PREVIOUS_RESET';
export const GROP_PREVIOUS_FILL = 'GROP_PREVIOUS_FILL';

export const GROP_SAVE = 'GROP_SAVE';

export const GROP_CREATE = 'GROP_CREATE';

export const GROP_PREVIOUS_LOAD = 'GROP_PREVIOUS_LOAD';

export const GROP_STATUS = 'GROP_STATUS';

export const GROP_LOT_LOAD = 'GROP_LOT_LOAD';

export const START = 'START';
export const SUCCESS = 'SUCCESS';
export const FAIL = 'FAIL';
export const NEUTRAL = 'NEUTRAL';

export const ALREADY_COMPLETED = 'ALREADY_COMPLETED';
export const NO_PREVIOUS = 'NO_PREVIOUS';
export const HAS_PREVIOUS = 'HAS_PREVIOUS';

export const FEMALE = 'female';
export const MALE = 'male';
export const OTHER = 'other';

function connectionIsValid() {
  // return JWT.isValid();
  return true;
}

function testStorage() {
  const mod = 'psymetrik';
  try {
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return true;
  } catch (e) {
    return false;
  }
}

function invalidConnectionError(type, dispatch) {
  dispatch({ type, status: FAIL, message: 'Invalid connection' });
  dispatch({
    type: UI_DIALOG,
    payload: {
      debug: true,
      title: "Erreur d'initialisation",
      message: `<p>Désolé, nous éprouvons des problèmes avec le démarrage de la session. Vous avez les options suivantes:</p>
      <ul>
      <li><b>Réessayez à nouveau,</b> en cliquant le bouton à cet effet.</li>
      <li>Si le problème persiste, vous pouvez toujours <b>utilisez la version simplifiée</b> du test: n'ayez crainte, le résultat est bien le même.</li>
      </ul>
      <p>Dans le doute, n'hésitez pas à demander l'avis de votre professionel.</p>`,
    },
  });
}

export function debug() {
  // hmmm... not sure this is necessary
  return (dispatch, getState) => {
    const {
      grop: { version },
    } = getState();

    // version: ${navigator && navigator.appVersion}
    const payload = `grop: ${version}
navigateur:
  app: ${navigator && navigator.appName}
  code: ${navigator && navigator.appCodeName}
  platform: ${navigator && navigator.platform}
  agent: ${navigator && navigator.userAgent}
  engine: ${navigator && navigator.product}
stockage:
  cookie: ${navigator && navigator.cookieEnabled ? 'ok' : '#error#'}
  storage: ${testStorage() ? 'ok' : '#error#'}
token:
   valid: ${JWT.isValid()}
   expiration: ${JWT.isValid() && JWT.getExpiration()}`;

    /*
    no need to advertise
    dispatch({
      type: UI_DEBUG,
      payload,
    });
    */
    return payload;
  };
}

export function bugpost(commentaire) {
  return (dispatch, getState, { fetch } = {}) => {
    const debogue = dispatch(debug());

    const body = new FormData();
    body.append('commentaire', commentaire);
    body.append('debogue', debogue);

    if (!fetch) {
      return null;
    }

    return fetch(`${window.App.apiUrl}/debogue`, {
      method: 'POST',
      headers: new Headers(),
      body,
    });
  };
}

export function connect() {
  return dispatch => {
    // go
    dispatch({ type: GROP_CONNECT, status: START });

    // test cookies
    if (navigator && !navigator.cookieEnabled) {
      const message =
        "Vous pouvez toujours <b>utilisez la version simplifiée</b> du test: n'ayez crainte, le résultat est bien le même.";
      dispatch({ type: GROP_CONNECT, status: FAIL, message });
      dispatch({
        type: UI_DIALOG,
        payload: {
          title: "Cette application requiert l'utilisation des cookies",
          message,
          debug: true,
        },
      });
      return false;
    }

    return (
      fetch(`/session`, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'connect',
        }),
      })
        .then(response => response.json())
        // ok we got the token, let's validate this sucker
        .then(token => {
          if (token.message) {
            throw new Error(token.message);
          }

          JWT.save(token);

          const payload = {
            expiration: JWT.getExpiration(),
            issuedat: JWT.getIssuedAt(),
            connected: true,
          };

          dispatch({ type: GROP_CONNECT, status: SUCCESS, payload });
          return true;
        })
        .catch(err => {
          dispatch({ type: GROP_CONNECT, status: FAIL, message: err.message });
          dispatch({
            type: UI_DIALOG,
            payload: {
              title: 'Erreur critique',
              message: `<p>La connexion au serveur principal est impossible à ce moment.</p>
              <small><i>Message: ${err.message}</i></small>`,
              noclose: true,
            },
          });
          return false;
        })
    );
  };
}

export function refresh() {
  return dispatch => {
    // go
    dispatch({ type: GROP_REFRESH, status: START });

    const refreshToken = JWT.getRefreshToken();

    return (
      fetch(`/session`, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'refresh',
          token: refreshToken,
        }),
      })
        .then(response => response.json())
        // ok we got the token, let's validate this sucker
        .then(token => {
          if (token.message) {
            throw new Error(token.message);
          }

          JWT.save(token);

          const payload = {
            expiration: JWT.getExpiration(),
            issuedat: JWT.getIssuedAt(),
            connected: true,
          };

          dispatch({ type: GROP_REFRESH, status: SUCCESS, payload });
          return true;
        })
        .catch(err => {
          const message = `${err.message} (${refreshToken})`;
          dispatch({ type: GROP_REFRESH, status: FAIL, message });
          dispatch({
            type: UI_DIALOG,
            payload: {
              title: 'Erreur de rafraichissement',
              message,
            },
          });
          return false;
        })
    );
  };
}

export function save(silent, continueLater = false) {
  return (dispatch, getState, { client }) => {
    if (!connectionIsValid()) {
      return invalidConnectionError(GROP_SAVE, dispatch);
    }

    const disabled = getState().grop.nosave;

    if (disabled) {
      dispatch({
        type: GROP_SAVE,
        status: START,
        message: '***DISABLED***',
        payload: silent,
      });

      // reject
      return new Promise(resolve => {
        setTimeout(() => {
          dispatch({
            type: GROP_SAVE,
            status: SUCCESS,
            message: '***DISABLED***',
          });
          resolve('WOOHOO');
        }, 1000);
      });
    }

    const {
      grop: {
        resultId,
        fullyCompleted,
        sections,
        session: { startTime, duree },
      },
    } = getState();

    if (!resultId) {
      const message = 'Le test ne semble pas avoir été généré.';
      dispatch({ type: GROP_SAVE, status: FAIL, payload: message });
      dispatch({
        type: UI_DIALOG,
        payload: { title: 'Erreur de sauvegarde', message },
      });
      return false;
    }

    dispatch({ type: GROP_SAVE, status: START });

    // let's retrieve the results
    const answers = ['', '', '', ''];

    sections.map((section, sectionId) => {
      Object.keys(section.answers).forEach(questionId => {
        const value = section.answers[questionId];
        if (value) answers[sectionId] += `${questionId}:${value}\n`;
      });
      return true;
    });

    const now = new Date();

    const newDuree =
      Math.round((now.getTime() - startTime.getTime()) / 1000) + duree; // num seconds

    const input = {
      answers_section_0: answers[0],
      answers_section_1: answers[1],
      answers_section_2: answers[2],
      answers_section_3: answers[3],
      duree: `${newDuree}`,
      complete: fullyCompleted,
      continue_later: continueLater,
    };

    const variables = {
      id: `${resultId}`,
      input,
    };

    return client
      .request({
        query: `mutation resultUpdate($id: String!, $input: ResultUpdateInput!) {
          resultUpdate(input: $input, id: $id) {
            errors
            violations {
              path
              message
            }
          }
        }`,
        variables,
      }, { isMutation: true })
      .then(({ data: { resultUpdate: { errors, violations } } }) => {
        if (errors.length > 0) {
          throw new Error(errors[0]);
        } else if (violations.length > 0) {
          // returns { message, path }
          const { path, message } = violations[0];
          throw new Error(`${path}: ${message}`);
        } else {
          dispatch({ type: GROP_SAVE, status: SUCCESS });
          return true;
        }
      })
      .catch(err => {
        console.error(err);
        dispatch({ type: GROP_SAVE, status: FAIL, message: err.message });
        if (!silent) {
          dispatch({
            type: UI_DIALOG,
            payload: {
              title: 'Erreur de sauvegarde',
              message: `
                <p>Nous sommes incapables de sauvegarder les résultats de cette section à ce moment.</p>
                <p>Il est possible que ce soit dû à une perte de connexion momentanée du réseau. Vous pouvez techniquement poursuivre le test, une sauvegarde être tentée plus tard.</p>
                <small>Message: <i>${err.message}</i></small>
              `,
              actions: [
                <Button
                  variant="contained"
                  onClick={() => {
                    dispatch({
                      type: UI_DIALOG,
                      payload: false,
                    });
                    return dispatch(save());
                  }}
                >
                  Réessayer la sauvegarde
                </Button>,
              ],
            },
          });
        }
        return false;
      });
  };
}

export function load(continueToken) {
  return (dispatch, getState, { client }) => {
    if (!connectionIsValid()) {
      return invalidConnectionError(GROP_PREVIOUS_LOAD, dispatch);
    }

    if (!continueToken) {
      dispatch({
        type: GROP_PREVIOUS_LOAD,
        status: FAIL,
        message: 'empty token',
      });
      return false;
    }

    dispatch({ type: GROP_PREVIOUS_LOAD, status: START });

    return client
      .request({
        query: `query loadResult($token: [String]) {
          nodeQuery(
            filter: { conditions: { field: "field_continue_token", value: $token } }
          ) {
            count
            entities {
              ... on NodeResult {
                entityId
                fieldComplete
                fieldDuree
                fieldAnswersSection
                fieldAnswersSection1
                fieldAnswersSection2
                fieldAnswersSection3
                fieldFirstname
                fieldLastname
                fieldGender
                fieldGenderChosen
                fieldGenderGroup
                fieldBirthday {
                  value
                }
                fieldResidence
                fieldEmail
                fieldLot {
                  targetId
                  entity {
                    ... on NodeLot {
                      fieldLotStatus
                    }
                  }
                }
              }
            }
          }
        }
        `,
        variables: { token: continueToken },
      }, { useCache: false })
      .then(({ data: { nodeQuery: { entities } } }) => {
        if (entities.length > 0) {
          const {
            // fieldLot: { targetId },
            // fieldComplete,
            fieldAnswersSection,
            fieldAnswersSection1,
            fieldAnswersSection2,
            fieldAnswersSection3,
            fieldFirstname,
            fieldLastname,
            fieldGender,
            fieldGenderChosen,
            fieldGenderGroup,
            fieldBirthday,
            fieldResidence,
            fieldEmail,
            fieldDuree,
            entityId,
            fieldLot: {
              entity: { fieldLotStatus },
            },
          } = entities[0];

          if (fieldLotStatus !== 'open') {
            // throw new Error('Votre professionnel a désactivé ce code d\'accès');
            return 'closed';
          }

          const resultData = {};

          [
            fieldAnswersSection,
            fieldAnswersSection1,
            fieldAnswersSection2,
            fieldAnswersSection3,
          ].map(field => {
            if (field) {
              field.split('\n').map(line => {
                const a = line.split(':');
                if (a[0]) resultData[a[0]] = parseInt(a[1], 10);
                return true;
              });
            }
            return true;
          });

          const previousResult = {
            id: entityId,
            results: resultData,
            duree: fieldDuree,
            user: {
              firstname: fieldFirstname,
              lastname: fieldLastname,
              residence: fieldResidence,
              birthday: fieldBirthday.value,
              gender: fieldGender,
              gender_chosen: fieldGenderChosen,
              gender_group: fieldGenderGroup,
              email: fieldEmail,
            },
          };

          dispatch({
            type: GROP_PREVIOUS_LOAD,
            status: SUCCESS,
            message: 'previous exists',
            payload: previousResult,
          });

          return true;
        }
        dispatch({
          type: GROP_PREVIOUS_LOAD,
          status: NO_PREVIOUS,
          message: 'no previous test',
          payload: {},
        });

        return false;
      })
      .catch(({ message }) => {
        dispatch({ type: GROP_PREVIOUS_LOAD, status: FAIL, message });
        dispatch({
          type: UI_DIALOG,
          payload: { title: 'Erreur de chargement (c)', message },
        });
        return false;
      });
  };
}

export function create(user) {
  // args: firstname, lastname, gender, gender_birth, gender_chosen, gender_group, birthday, residence, email
  return (dispatch, getState, { client }) => {

    if (!connectionIsValid()) {
      return invalidConnectionError(GROP_CREATE, dispatch);
    }

    // let's realign gender (no pun intended)
    if (user.gender === OTHER) {
      user.gender = user.gender_birth;
      if (user.gender_chosen !== OTHER) {
        user.gender_group = '';
      }
    } else {
      // reset gender values: having trouble doing it in the form, taking the lazy way out
      ['gender_group', 'gender_chosen', 'gender_birth'].forEach(field => user[field] = '');
    }

    delete user.gender_birth;
    
    const debugInfo = dispatch(debug());
    
    const {
      grop: { lotId },
    } = getState();
    const input = {
      ...user,
      lot: `${lotId}`,
      debug: debugInfo,
    };
    
    dispatch({ type: GROP_CREATE, status: START, payload: input });
    return client
      .request({
        query: `
          mutation createResult($input: ResultCreateInput!) {
            resultCreate(input: $input) {
              violations {
                code
                path
                message
              }
              errors
              entity {
                entityId
              }
            }
          }
        `,
        variables: { input },
      }, { isMutation: true })
      .then(({ data: { resultCreate: { errors, violations, entity } } }) => {
        if (errors.length > 0) {
          throw new Error(errors[0]);
        } else if (violations.length > 0) {
          throw new Error(`${violations[0].message} - ${violations[0].path}`);
        } else {
          dispatch({
            type: GROP_CREATE,
            status: SUCCESS,
            payload: {
              id: entity.entityId,
              user,
            },
          });
          return true;
        }
      })
      .catch(({ message }) => {
        dispatch({
          type: GROP_CREATE,
          status: FAIL,
          payload: message,
          message,
        });
        dispatch({
          type: UI_DIALOG,
          payload: {
            title: 'Erreur de création (c)',
            message: `<p>Nous éprouvons des problèmes avec la création du test.</p>
      <p><small>Message: <i>${message}</i></small></p>`,
          },
        });
        return false;
      });
  };
}

export function setAnswer(id, val) {
  return {
    type: GROP_SET_ANSWER,
    payload: { id, val },
  };
}

export function setSection(section) {
  return {
    type: GROP_SET_SECTION,
    payload: section,
  };
}

export function setPrevious() {
  return {
    type: GROP_SET_PREVIOUS,
  };
}

export function setNext() {
  return {
    type: GROP_SET_NEXT,
  };
}

export function previousPage() {
  return {
    type: GROP_SET_PAGE_PREVIOUS,
  };
}

export function nextPage() {
  return {
    type: GROP_SET_PAGE_NEXT,
  };
}

export function fillPrevious() {
  return {
    type: GROP_PREVIOUS_FILL,
  };
}

export function resetPrevious() {
  return {
    type: GROP_PREVIOUS_RESET,
  };
}

export function setNumItems(payload) {
  return {
    type: GROP_SET_NUMITEMS,
    payload,
  };
}

export function setup({
  labels: labelsRaw,
  message,
  consignes0,
  consignes1,
  consignes2,
  consignes3,
  section0,
  section1,
  section2,
  section3,
  dialogcompleted,
  dialogquit,
  home,
  postcomplete,
  postquit,
}) {
  return (dispatch, getState) => {
    const dev = getParameterByName('dev') !== null;
    const prepopulate = getParameterByName('pp') !== null;
    const nosave = getParameterByName('ns') !== null;

    // const totalPerSection = [7, 11, 9, 6];
    const totalPerSection = [2, 2, 2, 2];
    const numitems = 5;

    const questions = dev
      ? [
          section0.slice(0, totalPerSection[0]),
          section1.slice(0, totalPerSection[1]),
          section2.slice(0, totalPerSection[2]),
          section3.slice(0, totalPerSection[3]),
        ]
      : [section0, section1, section2, section3];

    // inbound: title, consigne, labels
    const labels = JSON.parse(labelsRaw);

    const sections = [
      {
        ...labels.a,
        subtitle: 'Section A',
        questions: questions[0],
        answers: {},
        consignes: {
          viewed: false,
          body: consignes0.body,
          short: labels.a.consigne,
        },
      },
      {
        ...labels.b,
        subtitle: 'Section B',
        questions: questions[1],
        answers: {},
        consignes: {
          viewed: false,
          body: consignes1.body,
          short: labels.b.consigne,
        },
      },
      {
        ...labels.c,
        subtitle: 'Section C',
        questions: questions[2],
        answers: {},
        consignes: {
          viewed: false,
          body: consignes2.body,
          short: labels.c.consigne,
        },
      },
      {
        ...labels.d,
        subtitle: 'Section D',
        questions: questions[3],
        answers: {},
        consignes: {
          viewed: false,
          body: consignes3.body,
          short: labels.d.consigne,
        },
      },
    ];

    // imported from the reducer
    sections.forEach((section) => {
      // let fix the labels sorting while we're at is
      section.labels.reverse();
      section.completed = false;
      section.questions.forEach(question => {
        section.answers[question.id] = 0;
      });
    });

    // add content
    dispatch({
      type: UI_ADD_CONTENT,
      payload: {
        home: { title: home.title, body: home.body.value },
        dialogcompleted: {
          title: dialogcompleted.title,
          body: dialogcompleted.body.value,
        },
        dialogquit: {
          title: dialogquit.title,
          body: dialogquit.body.value,
        },
        postcomplete: {
          title: postcomplete.title,
          body: postcomplete.body.value,
        },
        postquit: { title: postquit.title, body: postquit.body.value },
      },
    });

    dispatch({ type: UI_MESSAGE, payload: message });

    // setup grop
    dispatch({
      type: GROP_SETUP,
      status: SUCCESS,
      payload: { sections, numitems, dev, prepopulate, nosave },
    });
  };
};

export function getLotIDFromName(title) {
  const decacher = `${10000 + Math.round(Math.random() * 89999)}`;

  return (dispatch, getState, { client }) => {
    return client
      .request(
        {
          query: `query getLotId($title: String!, $decacher: String!) {
          GetLotIDFromName(input: $title, decacher: $decacher)
        }`,
          variables: {
            title: title.trim(),
            decacher,
          },
        }, { useCache: false })
      .then(({ data: { GetLotIDFromName } }) => {
        if (GetLotIDFromName) {
          dispatch({ type: GROP_SET_LOTID, payload: GetLotIDFromName });
          return GetLotIDFromName;
        }

        return 0;
      });
    }
}

export function fillCurrentSection() {
  return (dispatch, getState) => {
    const { grop } = getState();
    grop.sections[grop.section].questions.forEach(({ id }) =>
      dispatch(setAnswer(id, Math.ceil(Math.random() * 5))),
    );
  }
}

export function fillEntireTest() {
  return (dispatch, getState) => {
    const { grop } = getState();
    [0, 1, 2, 3].forEach(section => {
      grop.sections[section].questions.map(({ id }) =>
        dispatch(setAnswer(id, Math.ceil(Math.random() * 5))),
      );
      if (section < 3) setSection(section + 1);
    });
  }
}

export function admin(action) {
  if (process.env.NODE_ENV !== 'production') {
    return (dispatch, getState, { client }) => {
      return client
        .request(
          {
            query: `query admin($action: String!) {
            admin(input: $action)
          }`,
            variables: { action },
          }, { useCache: false })
        .then(({ data: { admin } }) => {
          if (admin) {
            dispatch({ type: GROP_ADMIN, payload: { type: action, value: JSON.parse(admin) } });
            return admin;
          }

          return 0;
        });
      }
  }
}


export function quit() {
  return {
    type: GROP_QUIT,
  };
}

export function helloWorld() {
  return (dispatch, getState, { client }) =>
    client
      .request({
        query: 'query HelloWorld { HelloWorld }',
      })
      .then(() => true)
      .catch(() => false);
}
