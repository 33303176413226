import {
  UI_DIALOG,
  UI_REDIRECT,
  UI_SET_HELP,
  UI_ADD_CONTENT,
  UI_MESSAGE,
} from '../actions/ui';

export const initialState = {
  pathname: null,
  query: {},
  view: null,
  dialog: {
    title: '',
    message: '',
    open: false,
    noclose: false,
    debug: null,
    actions: [],
  },
  system_message: null,
  content: {},
  help: true,
  initialized: false,
};

export default function ui(state = initialState, { type, payload, status }) {
  const _state = { ...state };

  switch (type) {
    case UI_MESSAGE:
      _state.system_message = payload;
      return _state;

    case UI_DIALOG:
      if (payload === false) {
        _state.dialog = {
          title: '',
          message: '',
          open: false,
          noclose: false,
          actions: [],
        };
      } else {
        _state.dialog = {
          ...state.dialog,
          ...payload,
          open: true,
        };
      }
      return _state;

    case UI_REDIRECT:
      _state.view = payload;
      return _state;

    case UI_ADD_CONTENT:
      _state.content = { ..._state.content, ...payload };
      return _state;

    case UI_SET_HELP:
      _state.help = payload;
      return _state;

    default:
      return state;
  }
}
