import React from 'react';
import { Provider } from 'react-redux'
import withGraphQLClient from './lib/with-graphql-client';
import { ClientContext } from 'graphql-hooks'
import { StylesProvider, ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import getPageContext from './lib/material-ui';
import GlobalContainer from './lib/global-content';
import withReduxStore from './lib/with-store';
import Layout from "./components/Layout";
import Index from './pages';

class MyApp extends React.Component {
  /* static async getInitialProps({ Component, router, ctx }) {
    return {}
  }*/

  state = {
    next: null,
    current: null
  }

  constructor(props) {
    super(props);
    this.pageContext = getPageContext();
  }

  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }

  render () {
    const {graphQLClient, reduxStore} = this.props
    return (
      <Provider store={reduxStore}>
        <StylesProvider
          generateClassName={this.pageContext.generateClassName}
          sheetsRegistry={this.pageContext.sheetsRegistry}
          sheetsManager={this.pageContext.sheetsManager}
        >
          {/* ThemeProvider makes the theme available down the React
              tree thanks to React context. */}
          <ThemeProvider theme={this.pageContext.theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <ClientContext.Provider value={graphQLClient}>
              <GlobalContainer>
                <Layout>
                  <Index />
                </Layout>
              </GlobalContainer>
            </ClientContext.Provider>
          </ThemeProvider>
        </StylesProvider>
      </Provider>
    );
  }
}

export default withGraphQLClient(withReduxStore(MyApp));
