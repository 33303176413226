import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HelpIcon from '@material-ui/icons/Help';
import QuitIcon from '@material-ui/icons/ExitToApp';

const styles = {
  root: {
    width: 500,
    background: 'transparent',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    color: '#444',
  },
};

class BottomNav extends React.Component {
  state = {
    value: -1
  }

  handleChange = (event, value) => {
    switch (value) {
      case 0: // help
        this.props.help();
        break;

      case 1: // quit
        if (this.props.fullyCompleted) {
          this.props.complete();
        } else {
          this.props.quit();
        }
        break;
      default:
        break;
    }
  }

  render(){
    const { classes, fullyCompleted } = this.props;

    return <div className={classes.wrapper}>
        <BottomNavigation
          value={this.state.value}
          onChange={this.handleChange}
          showLabels
          className={classes.root}
        >
          <BottomNavigationAction data-cy="bottom-nav-help" style={fullyCompleted ? { display: 'none' } : {}} label="Aide" icon={<HelpIcon className={classes.icon} />} />
          <BottomNavigationAction data-cy="bottom-nav-continue-later" style={fullyCompleted ? { 'textTransform': 'uppercase' } : {}} label={fullyCompleted ? "Terminer" : "Poursuivre plus tard"} icon={<QuitIcon className={classes.icon} />} />
        </BottomNavigation>
      </div>;
  }
}

export default withStyles(styles)(BottomNav);
