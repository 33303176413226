export const UI_DIALOG = 'UI_DIALOG';
export const UI_REDIRECT = 'UI_REDIRECT';
export const UI_ADD_CONTENT = 'UI_ADD_CONTENT';
export const UI_SET_HELP = 'UI_SET_HELP';
export const UI_DEBUG = 'UI_DEBUG';
export const UI_MESSAGE = 'UI_MESSAGE';

export const UI_VIEW_CODE = 'code';
export const UI_VIEW_ACCESS = 'access';
export const UI_VIEW_HOME = 'home';
export const UI_VIEW_BUILD = 'build';
export const UI_VIEW_TEST = 'test';
export const UI_VIEW_COMPLETE = 'complete';
export const UI_VIEW_QUIT = 'quit';

export const SUCCESS = 'SUCCESS';
export const FAIL = 'FAIL';
export const START = 'START';
export const ALREADY = 'ALREADY';

export function setDialog(payload) {
  return {
    type: UI_DIALOG,
    payload,
  };
}

export function redirect(payload) {
  return function(dispatch) {
    dispatch({
      type: UI_REDIRECT,
      payload,
    });
  }
}

export function showHelp() {
  return {
    type: UI_SET_HELP,
    status: 'true',
    payload: true,
  };
}

export function hideHelp() {
  return {
    type: UI_SET_HELP,
    status: 'false',
    payload: false,
  };
}