import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'

import createRootReducer from '../reducers';

const exampleInitialState = {};

export function initializeStore (initialState = exampleInitialState, helpers) {
  const middleware = [thunkMiddleware.withExtraArgument(helpers)];
  return createStore(
    createRootReducer(),
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  )
}