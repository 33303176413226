/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';

import HelpIcon from '@material-ui/icons/Help';

import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';

import { withStyles } from '@material-ui/core/styles';

import TableFooter from '../test/TableFooter';
import BottomNav from '../test/BottomNav';

import DialogCompleted from '../test/DialogCompleted';
import DialogQuit from '../test/DialogQuit';

import {
  setAnswer,
  setNext,
  setPrevious,
  setSection,
  previousPage,
  nextPage,
  save,
  bugpost,
} from '../../actions/grop';

import {
  UI_VIEW_HOME,
  setDialog,
  redirect,
  showHelp,
  hideHelp,
} from '../../actions/ui';

import TestTheme from '../test/TestTheme';

const styles = theme => TestTheme(theme);

export class Test extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popover: {
        lexique: null,
        anchorEl: null,
      },
      showQuit: false,
      showSave: true,
      pinned: false,
    };
  }

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll);
    this.testResultId();
  }

  componentWillUpdate(nextProps) {
    this.testResultId();

    if (
      this.props.section !== nextProps.section &&
      !nextProps.sections[nextProps.section].consignes.viewed
    ) {
      this.props.showHelp();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
    clearInterval(this.autoSave);
    window.removeEventListener('scroll', this.handleScroll);
  }

  testResultId = async () => {
    if (!this.props.resultId || this.props.resultId < 10) {
      console.error('empty.resultId -- redirecting');
      const currentdate = new Date();
      const datetime = `${currentdate.getDate()}/(${currentdate.getMonth() + 1}/${currentdate.getFullYear()} @ ${currentdate.getHours()}:${currentdate.getMinutes()}`;
      this.props.setDialog({
        title: 'Erreur critique',
        message: `<p><b>Une erreur empêche la poursuite du test. </b></p>
        <p>Veuillez en aviser votre conseiller en prenant soin de mentionner le code suivant:
        <i>8001-A${this.props.resultId}</i>, ainsi que la date et l'heure, ${datetime}</p>
        <p>Nous vous prions de bien vouloir nous excuser pour ce désagrément.</p>
        `,
        noclose: true,
      });
      await this.props.bugpost(`Empty ID Bug Report: ${datetime}`);
      this.props.redirect(UI_VIEW_HOME);
    }
  };

  gropTable = () => {
    const {
      // void
      classes,
      sections,
      section,
      current,
      page,
      pageLength,
    } = this.props;

    const rows = [];
    const { labels } = sections[section];

    let i = 0;

    const pointer = page * pageLength;
    for (i = pointer; i < pointer + pageLength; i += 1) {
      const question = sections[section].questions[i];
      if (question) {
        const answer = sections[section].answers[question.id] || null;
        rows.push(
          <div
            data-cy={`row-${i}`}
            className={`${classes[i % 2 ? 'odd' : 'even']} ${
              answer ? classes.done : ''
            }
              ${classes.row}`}
            key={`row-${question.id}`}
          >
            <span className={classes.num}>{i + 1}</span>
            <div className={classes.question}>
              <div className={classes.label}>
                {question.label}
                {question.lexique &&
                  (section === 3 && (
                    <span className={classes.lexiqueSectionD}>
                      {question.lexique}
                    </span>
                  ))}
              </div>
              {question.lexique && section !== 3 && (
                <div style={{ marginLeft: 8, marginTop: 2 }}>
                  <HelpIcon
                    data-cy="inline-help"
                    onClick={evt => this.handlePop(evt, question.lexique)}
                    style={{
                      width: 18,
                      height: 18,
                      cursor: 'pointer',
                    }}
                  />
                </div>
              )}
            </div>

            <div className={classes.radios}>
              {labels.map((label, key) => {
                const selectionId = 5 - key;
                const boutonId = `b-${question.id}-${selectionId}`;
                const bouton = document.getElementById(boutonId);
                return (
                  <div
                    className={classes.radio}
                    data-cy={`radio-${selectionId}`}
                    key={`button-${question.id}-${selectionId}`}
                    onMouseOver={() => {
                      if (bouton) {
                        bouton.style.backgroundColor = '#aaa';
                        bouton.style.borderColor = '#777';
                      }
                    }}
                    onMouseOut={() => {
                      // sync these with stylesheet!
                      if (bouton) {
                        bouton.style.backgroundColor = '#eee';
                        bouton.style.borderColor = '#aaa';
                      }
                    }}
                    onClick={() => this.handleClick(question.id, selectionId)}
                    data-is-selected={answer === selectionId}
                    role="button"
                  >
                    <div
                      id={`b-${question.id}-${selectionId}`}
                      tabIndex={0}
                      className={`${classes.bouton} ${
                        answer === selectionId ? classes.boutonSelected : ''
                      }`}
                    />
                  </div>
                );
              })}
            </div>
          </div>,
        );
      }
    }

    const head = [];

    labels.forEach((label, key) => {
      head.push(
        <div data-cy={`th-`} key={`th-${label}`} className={classes.radio}>
          <div className={classes.headlabel}>{label}</div>
        </div>,
      );
      return null;
    });

    return (
      <div className={`${classes[`current-${current}`]}`}>
        <div className={`${classes.head} ${classes.row}`}>
          <div
            key="th-question"
            className={`${classes.headQuestion} ${classes.question}`}
          >
            {sections[section].consigne}
          </div>
          <div className={`${classes.headLegend} ${this.state.pinned ? classes.headLegendPinned : ''}`}>{head}</div>
        </div>
        {rows}
        <TableFooter {...this.props} />
      </div>
    );
  };

  handlePrevPage = () => {
    this.props.previousPage();
  };

  handleNextPage = () => {
    this.props.nextPage();
  };

  closeDrawer = () => {
    const { sections, section } = this.props;
    if (sections[section].consignes.viewed === false) {
      sections[section].consignes.viewed = true;
    }
    this.props.hideHelp();
  };

  handleClick = (questionId, value) => {
    document.activeElement.blur();

    this.props.setAnswer(questionId, value);

    this.forceUpdate();
  };

  closePop = () => {
    this.setState({ popover: { lexique: null, anchorEl: null } });
  };

  handlePop = (e, lexique) => {
    e.preventDefault();
    this.setState({ popover: { anchorEl: e.currentTarget, lexique } });
  };

  onScroll = e => {
    const PIN_CUTOFF = 320;
    const { pinned } = this.state;
    const y = window.scrollY;

    if ((y > PIN_CUTOFF && !pinned) || (y <= PIN_CUTOFF && pinned)) {
      this.setState({ pinned: y > 326 });
    }
  }

  quitDialog = async showQuit => this.setState({ showQuit });
  saveDialog = showSave => this.setState({ showSave });

  render() {
    const {
      // void
      sections,
      section,
      fullyCompleted,
      classes,
    } = this.props;

    if(!sections || !this.props.resultId) return null;

    const { popover } = this.state;

    let previousSectionCompleted = true; // section A should always be accessible

    return (
      <div className={classes.wrapper}>
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
          }}
        >
          <LinearProgress
            id="progress-full"
            variant="determinate"
            value={sections.completionRate || 0}
          />
        </div>

        {popover.anchorEl && (
          <Popover
            open={Boolean(popover.anchorEl)}
            onClose={this.closePop}
            anchorEl={popover.anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            style={{ maxWidth: '50%' }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div
              style={{
                padding: 15,
                background: '#ddeefb',
                fontSize: '.9em',
              }}
            >
              {popover.lexique}
            </div>
          </Popover>
        )}

        <DialogCompleted
          save={this.props.save}
          open={fullyCompleted && this.state.showSave}
          handleClose={() => this.setState({ showSave: false })}
        />

        <DialogQuit
          save={this.props.save}
          open={this.state.showQuit}
          handleClose={() => this.setState({ showQuit: false })}
        />

        <Drawer
          data-cy="consigne"
          onClick={this.closeDrawer}
          open={this.props.showingHelp}
          onClose={this.closeDrawer}
          anchor="right"
        >
          <div
            className={classes.consignes}
            style={{ padding: 20, maxWidth: 300 }}
          >
            <h2>
              <div className={classes.labelShort}>
                {sections[section].subtitle}
              </div>
              {sections[section].title}
            </h2>
            <h3>Consignes</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: sections[section].consignes.body.value.replace(
                  '[num]',
                  sections[section].questions.length,
                ),
              }}
            />
            <div
              style={{
                marginTop: 10,
                float: 'right',
              }}
            >
              <Button variant="contained" color="secondary">
                Débuter la série
              </Button>
            </div>
          </div>
        </Drawer>

        <div style={{ background: 'transparent' }}>
          <Stepper
            id="stepper"
            activeStep={2}
            nonLinear
            alternativeLabel
            style={{ backgroundColor: 'transparent' }}
          >
            {sections.map((item, key) => {
              const button = item.completed ? (
                <StepButton
                  data-cy={`step-${key}`}
                  completed={item.completed}
                  onMouseOver={() => this.setState({ stepperTooltip: key })}
                  onMouseOut={() => this.setState({ stepperTooltip: null })}
                  onClick={
                    previousSectionCompleted
                      ? () => this.props.setSection(key)
                      : null
                  }
                >
                  <Tooltip
                    title={`Retour vers la ${item.subtitle}`}
                    open={this.state.stepperTooltip === key}
                  >
                    <div>{item.subtitle}</div>
                  </Tooltip>
                </StepButton>
              ) : (
                <StepLabel>{item.subtitle}</StepLabel>
              );

              const comp = (
                <Step active={section === key} key={`${item.subtitle}`}>
                  {button}
                </Step>
              );
              previousSectionCompleted = item.completed;
              return comp;
            })}
          </Stepper>

          <LinearProgress
            id="progress-section"
            variant="determinate"
            value={sections[section].completionRate || 0}
          />

          <Paper style={{ marginBottom: 20 }}>
            <div className={classes.entete}>
              <div className={classes.enteteLeft}>
                {sections[section].title}
              </div>
              <div className={classes.enteteRight}>
                {sections[section].subtitle}
              </div>
            </div>
            {this.gropTable()}
          </Paper>
        </div>

        <BottomNav
          help={this.props.showHelp}
          save={this.props.save}
          quit={() => this.setState({ showQuit: true })}
          complete={() => this.setState({ showSave: true })}
          fullyCompleted={fullyCompleted}
        />
      </div>
    );
  }
}

Test.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.shape({
        long: PropTypes.string,
        short: PropTypes.string,
      }),
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          lexique: PropTypes.string,
        }),
      ),
      consignes: PropTypes.shape({
        body: PropTypes.shape({
          value: PropTypes.string,
        }),
        viewed: PropTypes.bool,
        short: PropTypes.string,
      }),
      answers: PropTypes.shape(),
    }),
  ).isRequired,
  section: PropTypes.number.isRequired,
  setAnswer: PropTypes.func.isRequired,
  setSection: PropTypes.func.isRequired,
  setDialog: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  fullyCompleted: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  current: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  hideHelp: PropTypes.func.isRequired,
  showHelp: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  showingHelp: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  pageLength: PropTypes.number.isRequired,
  resultId: PropTypes.number.isRequired,
};

const mapState = state => ({
  sections: state.grop.sections,
  section: state.grop.section,
  current: state.grop.current,
  page: state.grop.page.current,
  pageLength: state.grop.page.numitems,
  pageFull: state.grop.page.full,
  fullyCompleted: state.grop.fullyCompleted,
  startTime: state.grop.session.startTime,
  saving: state.grop.save.inprogress,
  isDev: state.grop.dev,
  resultId: state.grop.resultId,
  showingHelp: state.ui.help,
});

export default withStyles(styles)(
  connect(
    mapState,
    {
      setAnswer,
      setSection,
      setDialog,
      save,
      setNext,
      setPrevious,
      nextPage,
      previousPage,
      redirect,
      hideHelp,
      showHelp,
      bugpost,
    }
)(Test));
