import React from 'react';

const Error = ({ statusCode, message }) => {

  switch(statusCode){
    case 404:
      message = 'Cette page n\'existe pas';
      break;
    default:
  }

  if(!message) {
    message =  'Une erreur inconnue s\'est produite';
  }

  return <div style={styles.errorWrapper}>
    <div style={styles.error}>
      <style dangerouslySetInnerHTML={{ __html: 'body { margin: 0 }' }} />
      {statusCode ? <div style={styles.statusCode}>{statusCode}</div> : null}
      {message && <div style={styles.desc}>{message}</div>}
    </div>
  </div>
}

const styles = {
  errorWrapper: {
    fontFamily: [
      'apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Oxygen-Sans',
      'Ubuntu',
      'Cantarell',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(', '),
    height: '90vh',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  desc: {
    display: 'block',
    textAlign: 'left',
  },

  statusCode: {
    display: 'block',
    borderRight: '1px solid rgba(0,0,0,.3)',
    margin: 0,
    marginRight: '20px',
    padding: '10px 23px 10px 0',
    fontSize: 24,
    fontWeight: 'bold',
  },
}

export default Error;
