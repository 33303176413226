import { SheetsRegistry } from 'jss';
import { createMuiTheme } from '@material-ui/core/styles';
import { createGenerateClassName } from '@material-ui/styles';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  overrides: {
    /* MuiTooltip: {
      popper:{
        opacity: 1,
      },
      tooltip: {
        backgroundColor: '#911'
      },
    }, */
    MuiButton: {
      contained: {
        boxShadow: '0 none'
      }
    }
  },
  typography: {
    
    fontSize: 15, // default: 14
    body1: {
      color: 'rgba(0, 0, 0, 0.67)'
    }
  },
  palette: {
    background: {
      default: '#eee',
    },
    primary: {
      main: '#fa0',
    },
    secondary: {
      main: '#444',
    },
  },
  /* custom: {
    ...config,
    pad: t => ({
      ...t.typography.body1,
      color: '#ccc',
      padding: 30,
      margin: '0 auto',
      [t.breakpoints.up('md')]: {
        padding: '5vw 20vw'

      }
    }),
  } */
});

function createPageContext() {
  return {
    theme,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new SheetsRegistry(),
    // The standard class name generator.
    generateClassName: createGenerateClassName(),
  };
}

let pageContext;

export default function getPageContext() {
  // Make sure to create a new context for every server-side request so that data
  // isn't shared between connections (which would be bad).
  if (!process.browser) {
    return createPageContext();
  }

  // Reuse context on the client-side.
  if (!pageContext) {
    pageContext = createPageContext();
  }

  return pageContext;
}
