import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

import IconPreviousSkip from '@material-ui/icons/FirstPage'; //= <Icon>skip_previous</Icon>;
import IconPrevious from '@material-ui/icons/ChevronLeft'; // = <Icon>replay</Icon>;
import IconNext from '@material-ui/icons/ChevronRight'; //= <Icon>play_arrow</Icon>;
import IconNextSkip from '@material-ui/icons/LastPage'; //= <Icon>skip_next</Icon>;

import { withStyles } from '@material-ui/core/styles';

import { setNumItems } from '../../actions/grop';

import TestTheme from './TestTheme';

const styles = theme => TestTheme(theme);

const ALL = 120;

class CustomTableFooter extends React.Component {
  setNumItems = e => {
    this.props.setNumItems(e.target.value);
  };

  render() {
    const {
      // void
      sections,
      section,
      page,
      pageLength,
      pageFull,
      classes,
    } = this.props;

    const numPages = Math.ceil(sections[section].questions.length / pageLength);

    return (
      <div className={classes.footerWrapper}>
        <div className={classes.footerPage}>
          {this.props.numitems !== ALL && (
            <div className={`${classes.small} ${classes.footerPageNum}`}>
              Page <span data-cy="currentpage">{page + 1}</span> / <span data-cy="numpages">{numPages}</span>
            </div>
          )}

          <div className={`${classes.small} ${classes.footerNumItems}`}>
            Questions par page:
          </div>
          <div>
            <TextField
              data-cy="page-length"
              id="select-numitems"
              select
              value={this.props.numitems}
              onChange={this.setNumItems}
              style={{ width: 64, textAlign: 'right' }}
              SelectProps={{
                style: {
                  fontSize: '.85em',
                  color: '#555',
                  textAlign: 'right',
                  width: 46
                },
              }}
              InputProps={{
                disableUnderline: true,
              }}
            >
              {[5, 10, 15, 20, ALL].map(option => (
                <MenuItem key={`numitems-${option}`} value={option}>
                  {option === ALL ? 'Tout' : option}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>

        <div className={classes.footerNav}>
          <div style={classes.oddDiv}>
            <Button
              data-cy="section-prev"
              className={classes.footerButton}
              disabled={section < 1}
              onClick={() => this.props.setSection(section - 1)}
            >
              <Tooltip
                id="tooltip-section-prev"
                title="Section précédente"
              >
                <IconPreviousSkip />
              </Tooltip>
            </Button>
          </div>

          {this.props.numitems !== ALL && (
            <div style={classes.oddDiv}>
              <Button
                data-cy="page-prev"
                className={classes.footerButton}
                disabled={page === 0}
                onClick={this.props.previousPage}
              >
                <Tooltip
                  id="tooltip-page-prev"
                  title="Page précédente"
                >
                  <IconPrevious />
                </Tooltip>
              </Button>
            </div>
          )}

          {this.props.numitems !== ALL && (
            <div style={classes.oddDiv}>
              <Button
                data-cy="page-next"
                variant={
                  sections[section].completed || page >= numPages || !pageFull
                    ? 'text'
                    : 'contained'
                }
                className={classes.footerButton}
                disabled={page >= numPages || !pageFull}
                onClick={this.props.nextPage}
              >
                <Tooltip
                  id="tooltip-page-next"
                  title="Page suivante"
                >
                  <IconNext />
                </Tooltip>
              </Button>
            </div>
          )}

          <div style={classes.oddDiv}>
            <Button
              className={classes.footerButton}
              data-cy="section-next"
              variant={
                !sections[section].completed || section > 2 ? 'text' : 'contained'
              }
              disabled={!sections[section].completed || section > 2}
              onClick={() => {
                // THIS SAVES RESULTS ON EACH SECTION CHANGE!
                this.props.save();
                this.props.setSection(section + 1);
              }}
            >
              <Tooltip
                id="tooltip-section-next"
                title="Section suivante"
              >
                <IconNextSkip />
              </Tooltip>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  numitems: state.grop.page.numitems,
});

export default connect(
  mapState,
  { setNumItems },
)(withStyles(styles)(CustomTableFooter));
