import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = ({ centered, message }) => {
  const style = {
    fontWeight: 500,
    fontSize: '.8em',
    marginTop: 15,
    textTransform: 'uppercase',
    letterSpacing: '.1em',
    lineHeight: '1.6em',
  };

  const centerStyle = centered ? {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  } : {}

  return (
    <div style={{ textAlign: 'center', ...centerStyle }}>
      <CircularProgress size={60} thickness={4} />
      <div style={style}>
        {message || "Chargement en cours..."}
      </div>
    </div>
  );
}

export default Loading;
